<template>
  <div
    class='sticky top-16 z-10 full-width-inside-layout-container left-0 right-0 text-white bg-transparent'
    :class='navigationHeaderCssClasses'
    :style='`${navigationHeaderStyle}`'>
    <div class='layout-container'>
      <div v-if='navigationHtmlContent'
        class='text-2xl uppercase px-2 lg:px-0'
        :class='navigationHtmlCssClasses'
        v-html='navigationHtmlContent'></div>
      <ul 
        class='sticky top-24 overflow-x-auto flex flex-row justify-start items-start gap-x-4 navigation-links'
        :class='tabsContainerCssClasses'>
        <li v-for='(tab, index) in tabs'
          :key='`tab-link-${tab.title}-${index}`'
          class=''>
          <router-link v-if='tab.actionType==="open_internal_route"'
            :to='{ name: tab.link }'
            class='opacity-90 flex flex-row justify-start whitespace-nowrap text-base lg:text-lg px-2 pt-8 lg:pt-12 border-b-2 border-transparent uppercase hover:shadow-md'
            :class='linkCssClass(tab.activeCssClass)'>
            {{ tab.title }}
          </router-link>
          <a v-else-if='tab.actionType==="open_external_link"'
            target='_blank' 
            :href='tab.link'
            class='opacity-90 flex flex-row justify-start whitespace-nowrap text-base lg:text-lg px-2 pt-8 lg:pt-12 border-b-2 border-transparent uppercase hover:shadow-md'
            :class='linkCssClass(tab.activeCssClass)'>
            {{ tab.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TabsPageNavigation',
  components: {
  },
  props: {
    mainBodyContent: {
      type: String,
      default: '',
    },
    sidebarPageTitle: {
      type: String,
      default: '',
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    customBackgroundStyle: {
      type: String,
      default: '',
    },
    navigationHeaderCssClasses: {
      type: String,
      default: '',
    },
    tabsContainerCssClasses: {
      type: String,
      default: '',
    },
    tabCssClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    tabs () {
      return this.sidebarLinks // keep the same props as the sidebar page
    },
    navigationHtmlContent () {
      return this.sidebarPageTitle // keep the same props as the sidebar page
    },
    navigationHeaderStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
  },
  methods: {
    linkCssClass (activeCssClass) {
      return `${this.tabCssClasses} ${activeCssClass}`
    },
  },
}
</script>

<style lang='scss' scoped>
.navigation-links .router-link-active {
  @apply font-bold opacity-100 border-white;
}
    
@media (min-width: 1024px) {
}
</style>


